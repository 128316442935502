import React from "react";
import BigCalendarClassItem from "./BigCalendarClassItem";

function BigCalendarListWrap({ classes, user, handlePostDownload }) {
  return (
    <ul className="cal-list-wrap">
      {Array.isArray(classes) &&
        classes.map((classInfo) => (
          <BigCalendarClassItem
            key={`${classInfo.timetable_seq}_${classInfo.user_seq}_${classInfo.order_seq}`}
            classInfo={classInfo}
            user={user}
            handlePostDownload={handlePostDownload}
          />
        ))}
    </ul>
  );
}

export default React.memo(BigCalendarListWrap);
